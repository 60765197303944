import { Menu } from 'antd';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import React from 'react';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? pathArray[2] === 's'
                    ? 'admins'
                    : 'home'
                  : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`,
            ]
          : []
      }
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      <Menu.Item
        onClick={() => {
          history.push(`${path}/clients`);
        }}
        icon={!topMenu && <FeatherIcon icon="book" />}
        key="clients"
      >
        <NavLink to={`${path}/clients`}>Clientes</NavLink>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          history.push(`${path}/shipments`);
        }}
        icon={!topMenu && <FeatherIcon icon="truck" />}
        key="shipments"
      >
        <NavLink to={`${path}/shipments`}>Embarques</NavLink>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          history.push(`${path}/admins`);
        }}
        icon={!topMenu && <FeatherIcon icon="user" />}
        key="admins"
      >
        <NavLink to={`${path}/admins`}>Administradores</NavLink>
      </Menu.Item>
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
