import { theme, darkTheme } from './theme/themeVariables';
import constants from './constants';
const config = {
  darkMode: false,
  topMenu: true,
  rtl: false,
  theme,
  darkTheme,
};

export const amplifyConfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: constants.awsRegion,
    identityPoolId: constants.identityPoolId,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: constants.userPoolId,
    userPoolWebClientId: constants.userPoolWebClientId,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or n ot
    mandatorySignIn: false,
  },
  Storage: {
    AWSS3: {
      bucket: constants.s3PublicFiles,
      region: constants.awsRegion,
    },
  },
};

export default config;
