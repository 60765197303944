import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Shipments = lazy(() => import('../../container/shipments/Shipments'));

const ShipmentsRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      ∏
      <Route exact path={`${path}/shipments`} component={Shipments} />
    </Switch>
  );
};

export default ShipmentsRoutes;
