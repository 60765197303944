import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Users = lazy(() => import('../../container/users/Users'));
const Clients = lazy(() => import('../../container/users/Clients'));

const UserRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/admins`} component={Users} />
      <Route exact path={`${path}/clients`} component={Clients} />
    </Switch>
  );
};

export default UserRoutes;
